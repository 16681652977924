var isPage = "";
var Animation = {
	top : 0,
	show: 0,
	pos : [0,0],
	id  : ""
}
var animations = [];
var Scrollbar, scrollbar, menuTimer;
var common = {
	debug: false,
	hasSE: true,
	winH : 0,
	topD: 0,
	beforeW: 0,
	init: ()=>{
		Scrollbar = window.Scrollbar;
		
		if(isPage === "product"){
			product.init();
		}else if(isPage === "showroom"){
			showroom.init();
		}else if(isPage === "contact"){
			contact.init();
		}
		if(isPage === "index"){
			if(isSP){
				$("#video_pc").remove();
				var imgArry = [
					"/assets/images/index/diningtable/option/img_add0_0_sp.jpg",
					"/assets/images/index/diningtable/option/img_add1_0_sp.jpg",
					"/assets/images/index/diningtable/option/img_add2_0_sp.jpg",
					"/assets/images/index/coffee/option/img_add0_0_sp.jpg",
					"/assets/images/index/coffee/option/img_add1_0_sp.jpg",
					"/assets/images/index/coffee/option/img_add2_0_sp.jpg",
					"/assets/images/index/sofa/option/img0_0_sp.jpg",
					"/assets/images/index/sofa/option/img1_0_sp.jpg",
					"/assets/images/index/sofa/option/img2_0_sp.jpg"
				]
			}else{
				var imgArry = [
					"/assets/images/index/diningtable/option/img_add0_0.jpg",
					"/assets/images/index/diningtable/option/img_add1_0.jpg",
					"/assets/images/index/diningtable/option/img_add2_0.jpg",
					"/assets/images/index/coffee/option/img_add0_0.jpg",
					"/assets/images/index/coffee/option/img_add1_0.jpg",
					"/assets/images/index/coffee/option/img_add2_0.jpg",
					"/assets/images/index/sofa/option/img0_0_sp.jpg",
					"/assets/images/index/sofa/option/img1_0_sp.jpg",
					"/assets/images/index/sofa/option/img2_0_sp.jpg"
				]
			}
		}else{
			var imgArry = [];
			$("img").each(function(i){
				var src = $(this).attr("src");
				if(src != ""){
					imgArry.push(src);
				}
			});
		}
		
		preload.startLoad(imgArry.length, imgArry).done(function(){
			setTimeout(function(){
				common.activeLoading().done(function(){
					if(isPage === "index"){
						common.start();
					}else{
						common.startPage();
					}
				});
			}, 600);
		});
	},
	startToggleMenu: function(){
		menuTimer = setInterval(function(){
			if(!$html.classList.contains("menu")){
				toggleAnimation();
			}
		}, 10000);
		function toggleAnimation(){
			let $menu = $("#btn_menu");
			if($menu.hasClass("transform")){
				$menu.removeClass("transform");
				$menu.addClass("reset");
			}else{
				$menu.removeClass("reset");
				$menu.addClass("transform");
				setTimeout(function(){
					if(!$html.classList.contains("menu")){
						toggleAnimation();
					}
				},2000);
			}
		}
	},
	activeLoading: function(){
		var defer = $.Deferred();
		var $loading = $("#loading");
		if(!$loading .hasClass("active")){
			$loading .addClass("active");
			setTimeout(function(){
				$loading.addClass("show_logo");
				setTimeout(function(){
					$loading .addClass("show_text");
					if(isPage === "index"){
						let player;
						if(isSP){
							player = playerSP;
						}else{
							player = playerPC;
						}
						player.load();
						if(common.debug){
							setTimeout(function(){
								if(isPage === "index"){
									if(isSP || isOldType || isIPad){
										
									}else{
										scrollbar = Scrollbar.init( document.querySelector('main') );
									}
								}
								if (player.readyState > 3) {
									movieisReady();
								}else{
									player.addEventListener('canplaythrough', function (e) {
										movieisReady();
									});
								}
								function movieisReady(){
									$html.classList.remove("loading");
									defer.resolve();
									setTimeout(function(){
										$loading .remove();
									},600);
								}
							}, 600);
						}else{
							//console.log("here")
							if (player.readyState > 3) {
								movieisReady();
							}else{
								//onsole.log(player, player.readyState)
								player.addEventListener('canplaythrough', function (e) {
									movieisReady();
								});
							}
							function movieisReady(){
								$loading.addClass("show_sound");
								$("#select_sound li a").off().on("click", function(){
									let idx = $("#select_sound li a").index(this);
									movie.changeSound(idx);
									setTimeout(function(){
										$html.classList.remove("loading");
										if(isPage === "index"){
											if(isSP || isOldType || isIPad){
												
											}else{
												scrollbar = Scrollbar.init( document.querySelector('main') );
											}
										}
										defer.resolve();
										setTimeout(function(){
											$loading.remove();
										},600);
									}, 600);
								});
							}
						}
					}else{
						setTimeout(function(){
							$html.classList.remove("loading");
							defer.resolve();
							setTimeout(function(){
								$loading .remove();
							},600);
						}, 600);
					}
				}, 300);

			}, 800);
		}else{
			$loading.removeClass("active");
			$loading.removeClass("show_logo");
			$loading.removeClass("show_text");
		}
		return defer.promise();
	},
	start: function(){
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		home.start();
		common.nav();
		common.resize();

		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});

		if(isPage === "index"){
			if(isSP || isOldType || isIPad){
				let topD = $(document).scrollTop();
				let btmD = topD + window.innerHeight;
				home.scrl(topD, btmD);

				$(document).off("scroll touchmove").on("scroll touchmove", function(){
					let topD = $(document).scrollTop();
					let btmD = topD + window.innerHeight;
					common.topD = topD;
					home.scrl(topD, btmD);
				});
			}else{
				$("#index").addClass("smooth");
				scrollbar.addListener(listener);
				function listener(status){
					common.topD = status.offset.y;
					let topD = status.offset.y;
					let btmD = topD + window.innerHeight;
					home.scrl(topD, btmD);
				}
			}
			common.startToggleMenu();
		}else{
			$(document).off("scroll touchmove").on("scroll touchmove", function(){
				common.scrl();
			});
		}
	},
	startPage: function(){
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		common.nav();
		common.resize();
		common.checkHash();
		setTimeout(function(){
			common.scrl();
		}, 100);

		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
		$(document).off("scroll touchmove").on("scroll touchmove", function(){
			common.scrl();
		});
		common.startToggleMenu();
	},
	nav: ()=>{
		$("a.btn_pagetop").off().on("click", function(){
			if(isPage === "index"){
				if(isSP || isOldType || isIPad){
					common.scrollToPosition(0, 1000);
				}else{
					scrollbar.scrollTo(0,0,1000,{});
				}
			}else{
				common.scrollToPosition(0, 1000);
			}
		});
		$("h2#ttl_h2 a").off().on("click", function(){
			if(isPage === "index"){
				if(isSP || isOldType || isIPad){
					common.scrollToPosition(0, 1000);
				}else{
					scrollbar.scrollTo(0,0,1000,{});
				}
			}else{
				common.scrollToPosition(0, 1000);
			}
		});
		
		$("a#btn_menu").off().on("click", function(){
			if(!$html.classList.contains("menu")){
				$html.classList.add("menu");
				$("a#btn_menu").removeClass("transform");
			}else{
				$html.classList.remove("menu");
			}
		});
		//share
		$(".sharewith li a").off().on("click", function(){
			$this = $(this);
			var idx = $(".sharewith li a").index(this);
			var url = $(this).attr("data-share");
			shareTxt(idx,url);
		});
		function shareTxt(num,shareUrl){
			if(num === 0){
				//Twitter
				var txt = $this.attr("data-text");
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&amp;text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436');
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436');
			}else{
				//LINE
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		common.winH = winH;
		if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}
		var $body = $("body");
		//scrollbar.update();
		if(isPage === "index"){
			if(common.beforeW != winW){
				home.resize();
			}
		}else{
			common.checkHeight();
		}
		common.beforeW = winW;
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	checkHeight: function(){
		animations = [];
		$(".a").each(function(i){
			let _a 		= object(Animation);
			_a.top 		= Math.floor( $(this).offset().top );
			_a.show 	= Math.floor( _a.top + window.innerHeight/3 );
			_a.pos 		= [];
			_a.pos.push( parseInt( $(this).attr("data-x")*2 ) );
			_a.pos.push( parseInt( $(this).attr("data-y")*2 ) );
			//console.log(_a.pos[1])
			animations.push(_a);
		});
		console.log(animations);
	},
	scrollToPosition: (posY, duration) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: duration,
			"easing" 	: ease,
			"complete"	: function(){
				
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height(),
			alen = animations.length;
		if(isPage === "index"){
			home.scrl(btmD);
		}else{
			// DOMのアニメーション
			for(var a = 0; a < alen; a++){
				if(a === alen - 1){
					if(btmD >= animations[a].show){
						activeAnimate(a);
					}
				}else{
					if(btmD >= animations[a].show){
						activeAnimate(a);
					}
				}
			}
			function activeAnimate(a){
				let $blk = $(".a").eq(a);
				if(!$blk.hasClass("animate")){
					$blk.addClass("animate");
					setTimeout(function(){
						$blk.addClass("animated");
					},600);
				}
			}
		}
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	},
	getJson: function(url,params){
		var defer 	= $.Deferred();
		var url 	= url;
		$.ajax({
			type 		: "GET",
			dataType	: 'json',
			url 		: url,
			data 		: params,
			success		: defer.resolve,
			error 		: defer.reject
		});
		return defer.promise();
	},
	checkHash: function(){
		let hash 	= location.hash;
		let ary 	= hash.split("#");
		if(ary.length > 1){
			let posY 	= Math.floor( $(hash).offset().top - $("header").outerHeight() );
			common.scrollToPosition(posY);
		}
	}
}
document.addEventListener('DOMContentLoaded', function () {
	ready.dom = true;
	//console.log("DOM is ready");
	isPage = $("body").attr("id");
	if(isPage === "index"){
		if(!isSP){
			var videoHTML = '<video id="video_pc" src="/assets/images/index/movie/video.mp4" loop playsinline muted></video>';
			$("#movie_wrap #player").append(videoHTML);
		}
		if(isSP){
			$(".product").each(function(){
				$(this).find(".img_main figure img").remove();
			});
		}
		movie.init();
		
	}else{
		common.init();
	}
});
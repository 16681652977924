var contact = {
	init: function(){
		var $btn = $(".btn_submit");
		$("input.checkbox").on("change", function(){
			let $this = $(this);
			if($this.is(":checked")){
				$btn.removeClass("disable");
			}else{
				$btn.addClass("disable");
			}
		});
		$(".check").on("change", function(e){
			let $input = $(this);
			let $parent = $(this).parent();
			let result = contact.checkVal($input.attr("type"), $input);
			if(result === 0){
				if(!$parent.hasClass("error")){
					$parent.addClass("error");
				}
			}else{
				if($parent.hasClass("error")){
					$parent.removeClass("error");
				}
			}
			console.log(result);
		});
	},
	checkVal: function(type, $this){
		var regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
		let val = $this.val();
		let len = val.length;
		let result = 0;
		if(type === "text"){
			if(len > 0){
				result = 1;
			}else{
				result = 0;
			}
		}else if(type === "email"){
			if(len > 0){
				if(regexp.test(val)){
					result = 1;
					$this.parent().removeClass("error");
				}else{
					result = 0;
					$this.parent().addClass("error");
				}
			}else{
				result = 0;
				$this.parent().addClass("error");
			}
		}else{
			if(len > 0){
				result = 1;
			}else{
				result = 0;
			}
		}
		return result;
	}
}
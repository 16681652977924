var showroom = {
	init: function(){
		let shopURL = '/assets/json/shops.json';
		let param 	= {};
		common.getJson(shopURL, param).done(function(json){
			showroom.makeHTML(json);
		});
	},
	makeHTML: function(json){
		let shopHTML = '';
		for(var i = 0; i < json.area.length; i++){
			let area = json.area[i];
			//console.log(area, area.name)
			shopHTML 	+= '<div id="area' + i + '" class="area">'
						+ '<div class="name_area">'
						+ '<h5>' + area.name + '</h5>'
						+ '</div>'
						+ '<div class="list_shop">'
						+ '<ul>'
			
			for(var j = 0; j < area.shops.length; j++){
				let s = area.shops[j];
				shopHTML 	+= '<li>'
							+ '<dl>'
							+ '<dt>' + s.name + '</dt>'
							+ '<dd>'
							+ '<p class="address">' + s.address + '</p>'
							+ '<p class="tel">' + s.tel + '</p>'
							+ '</dd>'
							+ '</dl>'
							+ '</li>';
			}
			shopHTML += '</ul></div></div>';
		}
		$("#othershops .shops").html(shopHTML);
	}
}
var products = [];
var options = [];
var product = {
	type : "",
	name: "",
	current: 0,
	max: 0,
	arry: [],
	init: function(){
		let param 		= {};
		let productURL 	= "/assets/json/products.json";
		let optionURL 	= "/assets/json/options.json";
		common.getJson(productURL, param).done(function(json){
			products = json.products;
			common.getJson(optionURL, param).done(function(json){
				options = json.options;
				product.makeList();
				product.checkURL();
			});
		});
	},
	makeList: function(){
		//product
		var productHTML = '';
		for(var i = 0; i < products.length; i++){
			let p = products[i];
			if(p.price2 != ""){
				var price2 = '<p class="price">' + p.price2 + '</p>';
			}else{
				var price2 = "";
			}
			productHTML += '<li class="a"><a href="javascript:void(0);" data-type="product" data-url="' + p.url + '" title="' + p.name + '">'
						+ 	'<div class="img">'
						+ 	'<figure style="background-image: url(/assets/images' + p.thumb +')"></figure>'
						+	'</div>'
						+	'<div class="text">'
						+	'<h4>' + p.name + '</h4>'
						+	'<p class="jp">' + p.name_jp + '</p>'
						+	'<p class="price">' + p.price + '</p>'
						+ 	price2
						+	'</div></a></li>';
		}
		$(".products ul").html(productHTML);

		//options
		var optionsHTML = '';
		for(var j = 0; j < options.length; j++){
			let o = options[j];
			optionsHTML += '<li class="a"><a href="javascript:void(0);" data-type="option" data-url="' + o.url + '" title="' + o.name + '">'
						+ 	'<div class="img">'
						+ 	'<figure style="background-image: url(/assets/images' + o.thumb +')"></figure>'
						+	'</div>'
						+	'<div class="text">'
						+	'<h4>' + o.name + '</h4>'
						+	'<p class="jp">' + o.name_jp + '</p>'
						+	'<p class="price">' + o.price + '</p>'
						+	'</div></a></li>';
		}
		$(".options ul").html(optionsHTML);
		product.nav();
	},
	nav: function(){
		$("ul.list li a").off().on("click", function(){
			let type = $(this).attr("data-type");
			let name = $(this).attr("data-url");
			let param = "?type=" + type + "&name=" + name;
			window.history.pushState(null, null, param);
			product.checkURL();
		});
		
		$("a#btn_close").off().on("click", function(){
			let param = "/product/";
			window.history.pushState(null, null, param);
			product.checkURL();
		});
		$("#modal_product a.btn_next").off().on("click", function(){
			product.inc();
		});
		$("#modal_product a.btn_prev").off().on("click", function(){
			product.dec();
		});
		$(document).off("keyup").on("keyup", function(e){
			if(e.keyCode === 39){
				//next
				product.inc();
			}else if(e.keyCode === 37){
				//prev
				product.dec();
			}
		});
	},
	inc: function(){
		product.current++;
		if(product.current >= product.max){
			product.current = 0;
		}
		let name 	= product.arry[product.current].url;
		let param 	= "?type=" + product.type + "&name=" + name;
		window.history.pushState(null, null, param);
		product.checkURL();
	},
	dec: function(){
		product.current--;
		if(product.current < 0){
			product.current = product.max-1;
		}
		let name = product.arry[product.current].url;
		let param = "?type=" + product.type + "&name=" + name;
		window.history.pushState(null, null, param);
		product.checkURL();
	},
	checkURL: function(){
		let arg 		= new Object,
			parameter 	= location.search.substring(1),
			pair 		= parameter.split('&');

		if(pair[0] === "" || pair[0].indexOf("ckcachecontrol") > -1){
			if($html.classList.contains("modal")){
				product.closeModal();
			}
		}else{
			for(var i = 0; pair[i]; i++) {
				var kv = pair[i].split('=');
				if(kv[0] === "type"){
					product.type = kv[1];
					if(kv[1] === "product"){
						product.arry = products;
					}else{
						product.arry = options;
					}
				}else if(kv[0] === "name"){
					product.name = kv[1]
				}
			}
			if($html.classList.contains("modal")){
				$html.classList.add("disable_modal");
				setTimeout(function(){
					product.changeDetail();
				},600);
			}else{
				product.changeDetail();
			}
			//console.log(product.type, product.name, product.arry)
		}
	},
	changeDetail: function(){
		product.max = product.arry.length;
		product.arry.filter(function(item, index){
			if(item.url === product.name){
				product.current = index;
			}
		});
		//scroll to top
		$("#product_inner").animate({
			scrollTop : "0px"
		},{
			"duration" 	: 400,
			"complete"	: function(){
				
			}
		});
		var cls = "";
		if(product.type === "product"){
			var type = "BASE PRODUCT";
			cls = "modal_product";
		}else{
			var type = "OPTION PRODUCT";
			cls = "modal_option"
		}
		$("#modal_product").removeClass();
		$("#modal_product").addClass(cls);
		$("#type_product p").html(type);

		var data = product.arry[product.current];
		var detail = data.detail[0];
		$("#ttl_product_detail h4").html(data.name);
		$("#ttl_product_detail p.jp").html(data.name_jp);
		$("#modal_product .lead p").html(detail.text);
		var imgHTML = '',
			navHTML = '',
			imgArry = [];
		for(var i = 0; i < detail.imgs.length; i++){
			var img = detail.imgs[i];
			if(i === 0){
				imgHTML += '<li class="active"><figure><img src="' + img + '"></figure></li>';
				navHTML += '<li><a class="active" href="javascript:void(0);"><figure><img src="' + img + '"></figure></a></li>';
			}else{
				imgHTML += '<li><figure><img src="' + img + '"></figure></li>';
				navHTML += '<li><a href="javascript:void(0);"><figure><img src="' + img + '"></figure></a></li>';
			}
			imgArry.push(img);
		}
		$("#modal_product .img_detail ul").html(imgHTML);
		$("#modal_product .nav_detail ul").html(navHTML);

		//価格
		let priceHTML 	= '',
			sizeHTML 	= '';
		for(var j = 0; j < detail.price.length; j++){
			let name = detail.price[j].name;
			let price = detail.price[j].price;
			if(detail.price[j].margin){
				priceHTML += '<li class="mb1">' + name + " " + price + '</li>';
			}else{
				priceHTML += '<li>' + name + " " + price + '</li>';
			}
		}
		$("#modal_product dl.dl_price ul").html(priceHTML);
		//size
		for(var j = 0; j < detail.size.length; j++){
			let name 	= detail.size[j].name;
			let size 	= detail.size[j].size;
			if(detail.size[j].margin){
				sizeHTML += '<li class="mb1">' + name + " " + size + '</a></li>';
			}else{
				sizeHTML += '<li>' + name + " " + size + '</a></li>';
			}
		}
		$("#modal_product dl.dl_size ul").html(sizeHTML);

		//option
		let optionHTML = '';
		for(var k = 0; k < detail.option.length; k++){
			let option = detail.option[k];
			let url = "";
			if(product.type === "product"){
				url = "?type=option&name=" + option.url;
			}else{
				url = "?type=product&name=" + option.url;
			}
			optionHTML += '<li class="option' + option.id + '"><a href="javascript:void(0);" data-url="' + url + '"><span class="icon"></span><span>' + option.name + '</span></a></li>'
		}
		$("#modal_product dl.option ul").html(optionHTML);

		preload.startLoad(imgArry.length,imgArry).done(function(){
			product.openModal();
		});
	},
	openModal: function(){
		$("#product_content_inner .img_detail").animate({
			scrollLeft : "0px"
		},10);
		if(!$html.classList.contains("modal")){
			$html.classList.add("modal");
			setTimeout(function(){
				$html.classList.add("show_modal");
				setTimeout(function(){
					product.optionNav();
					$html.classList.add("show_modal_content");
					product.slideshow();
				},600);
			},50);
		}else{
			product.optionNav();
			$html.classList.remove("disable_modal");
			product.slideshow();
		}
	},
	closeModal: function(){
		if($html.classList.contains("modal")){
			$html.classList.add("modal_close");
			$html.classList.remove("show_modal_content");
			setTimeout(function(){
				$html.classList.remove("show_modal");
				setTimeout(function(){
					$html.classList.remove("modal");
					$html.classList.remove("modal_close");
				}, 600);
			}, 600);
		}
	},
	slideshow: function(){
		/*
		$("#modal_product .nav_detail ul li a").off().on("click", function(){
			let $this = $(this);
			let idx = $("#modal_product .nav_detail ul li a").index(this);
			let $currentImg = $("#modal_product .img_detail ul li").eq(idx);
			if(!$currentImg.hasClass("active")){
				$("#modal_product .img_detail ul li").removeClass("active");
				$(".nav_detail ul li a").removeClass("active");
				$this.addClass("active");
				$currentImg.addClass("active");
			}
		});
		*/
		$("#modal_product .nav_detail ul li a").mouseenter(function(){
			let $this = $(this);
			let idx = $("#modal_product .nav_detail ul li a").index(this);
			let $currentImg = $("#modal_product .img_detail ul li").eq(idx);
			if(!$currentImg.hasClass("active")){
				$("#modal_product .img_detail ul li").removeClass("active");
				$(".nav_detail ul li a").removeClass("active");
				$this.addClass("active");
				$currentImg.addClass("active");
			}
		}).mouseleave(function(){
			
		});
	},
	optionNav: function(){
		$(".option_list li a").off().on("click", function(){
			let param = $(this).attr("data-url");
			window.history.pushState(null, null, param);
			product.changeURLbyOption();
		});
	},
	changeURLbyOption: function(){
		let arg 		= new Object,
			parameter 	= location.search.substring(1),
			pair 		= parameter.split('&');

		for(var i = 0; pair[i]; i++) {
			var kv = pair[i].split('=');
			if(kv[0] === "type"){
				product.type = kv[1];
				if(kv[1] === "product"){
					product.arry = products;
				}else{
					product.arry = options;
				}
			}else if(kv[0] === "name"){
				product.name = kv[1]
			}
		}
		$html.classList.add("disable_modal");
		setTimeout(function(){
			product.changeDetail();
		},600);
	}
}
var Logo = {
	span 	: [],
	left 	: 0,
	top 	: 0
}
var logos = [];
var home = {
	logoPos: [],
	beforeCount: -1,
	start: function(){
		if(common.hasSE){
			sound.init();
		}
		$("#blk_main").addClass("start");
		setTimeout(function(){
			movie.play();
			$("#blk_main").addClass("show_movie");
			home.resize();
			
			$(".logos .logo").each(function(num){
				$(this).find("span").each(function(i){
					let p = logos[num];
					var pos = [];
					for(var c = 0; c < p.span.length-1; c++){
						let span = p.span[i];
						let s = parseInt( span[c] );
						//console.log(span, s, span[span.length-1])
						if(c === span.length-2){
							if(span[span.length-1] === 0){
								pos[c] = s;
							}else{
								pos[c] = s;
							}
							//console.log(pos[c])
						}else{
							pos[c] = s;
						}
						pos[c] = pos[c].toFixed(2);
					}
					//console.log(i, pos)
					$(this).css({
						"transform": "translate3d(" + pos[0] + "px," + pos[1] + "px,0) rotate(" + pos[2] + "deg)"
					});
				});
			});
			setTimeout(function(){
				home.resize();
			}, 300);
		}, 400);
	},
	resize: function(){
		logos = [];
		if(isSP || isOldType){
			$(".logos").css({
				"height": $(document).height() + "px"
			});
		}
		$(".logos .logo").each(function(i){
			let $this = $(this);
			let l 	= object(Logo);
			if(i === 0){
				if(isSP || isOldType){
					l.top 	= Math.floor( $("#blk_product").offset().top - $(".logo").height()/2);
				}else{
					l.top 	= Math.floor( $("#blk_product").offset().top - $(".logo").height()/2);
				}
				//l.top += Math.floor(window.innerHeight + Math.random()*window.innerHeight);
			}else{
				l.top = $("#blk_product .product").eq(i-1).find(".options").offset().top - $this.height()/2;
			}
			
			l.show = Math.floor( l.top);
			//-console.log(i, l.top)
			l.left 	= Math.floor( Math.random()*75 );
			l.span 	= [];
			$(this).find("span").each(function(j){
				let span = [];
				span.push( Math.floor(Math.random()*200 - 100) );
				span.push( Math.floor(Math.random()*200 - 100) );
				span.push( Math.floor(Math.random()*360) );
				if(span[2] > 180){
					span.push(1);
				}else{
					span.push(0)
				}
				l.span.push(span);
			});
			
			logos.push(l);
			$(this).css({
				"left"	: l.left + "%",
				"top"	: l.top + "px"
			});
		});
		

		$(".bg_product").each(function(j){
			if(isSP){
				var posY = $("#blk_product .product").eq(j).offset().top - 84;
			}else{
				var posY = $("#blk_product .product").eq(j).offset().top + common.topD;
			}
			
			//console.log("position:", $("#blk_product .product").eq(j).position().top)
			$(this).css({
				"top" : posY + "px",
				"height": $("#blk_product .product").eq(j).outerHeight() + "px"
			});
		});
		$(".bg").each(function(i){
			if(isSP || isOldType){
				var posY = $(".block").eq(i).offset().top;
			}else{
				var posY = $(".block").eq(i).offset().top + common.topD;
			}
			
			if(i === 1){
				var _h = $(".block").eq(i).outerHeight()*1.5;
			}else{
				var _h = $(".block").eq(i).outerHeight()*1.2;
			}
			$(".bg").eq(i).css({
				"top" : posY + "px",
				"height": _h + "px"
			});
		});
		animations = [];
		$(".a").each(function(i){
			$(this).css({
				"transform": ""
			});
			let _a 		= object(Animation);
			if($(this).hasClass("ttl_inner")){
				if(isSP || isOldType){
					_a.top 		= Math.floor( $(this).offset().top );
				}else{
					_a.top 		= Math.floor( $(this).offset().top + common.topD );
				}
				_a.top = Math.floor(_a.top + window.innerHeight/3);
				//console.log(_a.top, $(this).offset().top + common.topD)
			}else{
				if(isSP || isOldType){
					_a.top 		= Math.floor( $(this).offset().top );
				}else{
					_a.top 		= Math.floor( $(this).offset().top + common.topD );
				}
			}
			_a.show 	= Math.floor( _a.top + window.innerHeight/3 );
			_a.pos 		= [];
			_a.pos.push( parseInt( $(this).attr("data-x")*2 ) );
			_a.pos.push( parseInt( $(this).attr("data-y")*2 ) );
			_a.sound = $(this).attr("data-sound");
			if(_a.pos[0] > 0){
				_a.aim = "minus";
			}else if(_a.pos[0] < 0){
				_a.aim = "plus";
			}else{
				_a.aim = "noaim";
			}
			//console.log(_a.pos[1])
			animations.push(_a);
		});
		//console.log(animations)
		home.scrl(common.topD, common.topD + window.innerHeight);
		//-console.log(animations);
	},
	scrl: function(topD, btmD){
		let alen = animations.length;
		if(topD >= $("#blk_about").offset().top){
			if(!$html.classList.contains("show_logo")){
				$html.classList.add("show_logo");
			}
		}else{
			if($html.classList.contains("show_logo")){
				$html.classList.remove("show_logo");
			}
		}
		home.bgScroll(topD, btmD);

		// DOMのアニメーション
		for(var a = 0; a < alen; a++){
			if(a === alen - 1){
				if(btmD >= animations[a].top){
					currentDiff(a);
				}
			}else{
				if(btmD >= animations[a].top){
					currentDiff(a);
				}
			}
		}
		function addActive($blk){
			if(!$blk.hasClass("active")){
				if( $blk.attr("data-sound") === "on" ){
					sound.play();
				}
				
				$blk.addClass("active");
				setTimeout(function(){
					$blk.addClass("show");
				}, 400);
			}
		}
		function removeActive($blk){
			if($blk.hasClass("show")){
				$blk.removeClass("active");
				$blk.removeClass("show");
			}
		}
		function currentDiff(a){
			//console.log(a)
			let blk 		= animations[a],
				$thisblk 	= $(".a").eq(a),
				_t 			= (blk.top - btmD)*-1,
				_max 		= Math.floor($(window).height()*.7),
				posY 		= 0,
				posX 		= 0;

			if($thisblk.attr("data-svg") === "1"){
				if(!$thisblk.hasClass("active_svg")){
					$thisblk.addClass("active_svg")
				}
			}
			if(blk.pos[1] > 0){
				posY = blk.pos[1] -  ( _t * (blk.pos[1] / _max));
				if(posY < 0){
					posY = 0;
					addActive($thisblk);
				}else{
					removeActive($thisblk);
				}
			}
			
			if(blk.aim === "plus"){
				//+ -> -
				posX = blk.pos[0] -  ( _t * (blk.pos[0] / _max));
				if(posX > 0){
					posX = 0;
					addActive($thisblk);
				}else{
					removeActive($thisblk);
				}
			}else if(blk.aim === "minus"){
				//+ ? -
				posX = blk.pos[0] -  ( _t * (blk.pos[0] / _max));
				if(posX < 0){
					posX = 0;
					addActive($thisblk);
				}else{
					removeActive($thisblk);
				}
			}
			let xDiff 	= _t*(blk.pos[0] / _max);

			if(blk.pos[0] < 0){
				posX = blk.pos[0] + xDiff*-1;
				if(posX > 0){ 
					posX = 0;
					$thisblk.addClass("animate");
				}
			}else{
				posX = blk.pos[0] - xDiff;
				if(posX < 0){ 
					posX = 0;
					$thisblk.addClass("animate");
				}
			}
			$thisblk.css({
				"transform": "translate3d(" + posX + "px," + posY + "px, 0)"
			});
		}
	},
	bgScroll: function(topD, btmD){
		for(var l = 0; l < logos.length; l++){
			if(btmD > logos[l].show){
				logoMotion(l);
			}else{
				logoRandom(l);
			}
		}
		function logoMotion(num){
			let $logo = $(".logos .logo").eq(num);
			//console.log(num, $logo)
			if(!$logo.hasClass("active")){
				$(".logos .logo").eq(num).find("span").each(function(i){
					let pos 	= logos[num].span[i];
					let $this 	= $(this);
					$this.css({
						"transform": "translate3d(" + pos[0] + "px,0px,0) rotate(0deg)"
					});
					setTimeout(function(){
						$logo.addClass("active");
					},400);
				});
			}
		}
		function logoRandom(num){
			let $logo = $(".logos .logo").eq(num);
			//console.log(num, $logo)
			if($logo.hasClass("active")){
				$logo.removeClass("active");
				$(".logos .logo").eq(num).find("span").each(function(i){
					let pos 	= logos[num].span[i];
					let $this 	= $(this);
					$this.css({
						"transform": "translate3d(" + pos[0] + "px, " + pos[1] + "px,0) rotate(" + pos[2] + "deg)"
					});
				});
			}
		}
		/*
		for(var l = 0; l < logos.length; l++){
			if(btmD > logos[l].top){
				logoMotion(l)
			}
		}
		function logoMotion(num){
			let logoTop = logos[num].top;
			let h 		= common.winH*2;
			let t 		= (logoTop - topD)/h;
			
			let per 	= Math.floor(t/h*100)/100;
			//console.log(num, per);
			//分裂したロゴの収束
			$(".logos .logo").eq(num).css({
				"transform": "translate3d(0px," + topD*0.05 + "px,0)"
			});

			if(per >= 0){
				$(".logos .logo").eq(num).find("span").each(function(i){
					let p = logos[num];
					var pos = [];
					for(var c = 0; c < p.span.length-1; c++){
						let span = p.span[i];
						let s = parseInt( span[c] );
						//console.log(span, s, span[span.length-1])
						if(c === span.length-2){
							if(span[span.length-1] === 0){
								pos[c] = s*t;
							}else{
								pos[c] = s + (360 - s)*(1-t);
							}
							//console.log(pos[c])
						}else{
							pos[c] = s*t;
						}
						pos[c] = pos[c].toFixed(2);
					}
					//console.log(i, pos)
					$(this).css({
						"transform": "translate3d(" + pos[0] + "px," + pos[1] + "px,0) rotate(" + pos[2] + "deg)"
					});
				});
			}
		}
		*/
	}
}
var sound = {
	se: "",
	init: function(){
		sound.se = new Howl({
			src: ['/assets/se/sound0.mp3'],
			volume: .1
		});
		if(isSP){
			document.addEventListener('touchstart', initAudioContext);
		}else{
			document.addEventListener('click', initAudioContext);
		}
		function initAudioContext(){
			document.removeEventListener('touchstart', initAudioContext);
			document.removeEventListener('click', initAudioContext);
			// wake up AudioContext
			let ctx = new AudioContext();
			const emptySource = ctx.createBufferSource();
			emptySource.start();
		}
		$(document).off().on("keyup", function(e){
			//console.log(e.keyCode)
			sound.play();
		});
	},
	play: function(){
		if(common.hasSE){
			if(!$("#glbnav .sound dl").hasClass("sound_off")){
				sound.se.play();
			}
		}
	}
}
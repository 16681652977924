var playerPC,playerSP,videoTimer;
var isPlaying, isSPPlaying;
var movie = {
	init: function(){
		if(isSP){
			playerSP 		= $("#video_sp")[0];
			if(common.debug){
				playerSP.muted  = false;
				playerSP.volume = .5;
			}
			
			isSPPlaying 	= playerSP.currentTime > 0 && !playerSP.paused && !playerSP.ended && playerSP.readyState > 2;
			//playSPPromise 	= playerSP.play();
		}else{
			playerPC = $("#video_pc")[0];
			playerSP = $("#video_sp")[0];
			if(common.debug){
				playerPC.volume = .5;
				playerSP.volume = .5;
				playerPC.muted = false;
				playerSP.muted = false;
			}
			isSPPlaying = playerSP.currentTime > 0 && !playerSP.paused && !playerSP.ended && playerSP.readyState > 2;
			isPlaying = playerPC.currentTime > 0 && !playerPC.paused && !playerPC.ended && playerPC.readyState > 2;
			//playPromise 	= playerPC.play();
			//playSPPromise 	= playerSP.play();
		}
		ready.movie = true;
		movie.nav();
		common.init();
	},
	nav: function(){
		$(".sound li a").off().on("click", function(){
			var idx = parseInt( $(this).attr("data-sound") );
		//	console.log(idx);
			movie.changeSound(idx);
		});
	},
	changeSound: function(idx){
		if(isSP){
			if(idx === 0){
				//on
				$(".sound dl").removeClass();
				$(".glbnav dl").removeClass();
				$(".sound dl").addClass("sound_on");
				$(".glbnav dl").addClass("sound_on");
				if(playerSP.muted){
					playerSP.muted = false;
					playerSP.volume = .5;
				}
			}else{
				//off
				$(".sound dl").removeClass();
				$(".glbnav dl").removeClass();
				$(".sound dl").addClass("sound_off");
				$(".glbnav dl").addClass("sound_off");
				if(!playerSP.muted){
					playerSP.muted = true;
					playerSP.volume = 0;
				}
			}
		}else{
			if(idx === 0){
				//on
				$(".sound dl").removeClass();
				$(".glbnav dl").removeClass();
				$(".sound dl").addClass("sound_on");
				$(".glbnav dl").addClass("sound_on");
				if(playerPC.muted){
					playerPC.muted = false;
					playerPC.volume = .5;
				}
			}else{
				//off
				$(".sound dl").removeClass();
				$(".glbnav dl").removeClass();
				$(".sound dl").addClass("sound_off");
				$(".glbnav dl").addClass("sound_off");
				if(!playerPC.muted){
					playerPC.muted = true;
					playerPC.volume = 0;
				}
			}
		}
		
	},
	play: function(){
		movie.nav();
		if(isSP){
			if(!isSPPlaying){
				playerSP.play();
			}
		}else{
			if(isSmallScreen){
				if(!isSPPlaying){
					if(isPlaying){
						playerPC.pause();
					}
					playerSP.play();
				}
			}else{
				if(!isPlaying){
					//console.log(playerSP, playerPC)
					if(isSPPlaying){
						playerSP.pause();
					}
					playerPC.play();
				}
			}
		}
	}
}